import Icon from "@/components/Icon";
import Modal from "@/components/Modal";
import { auth } from "@/firebase";
import { useModals } from "@/router";
import { parseError } from "@/utils/parseError";
import { passwordSchema } from "@/utils/passwordSchema";
import { Dialog } from "@headlessui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from "firebase/auth";
import { useForm } from "react-hook-form";
import { z } from "zod";

export default function ChangePasswordModal() {
  const modals = useModals();

  return (
    <Modal onClose={() => modals.close()}>
      <Dialog.Title as="h3" className="text-2xl font-medium leading-6 mt-2 mb-6">
        Change password
      </Dialog.Title>

      {/* Change Password */}
      <ChangePasswordForm />
      {/*  */}
    </Modal>
  );
}

const schema = z
  .object({
    email: z.string().email(),
    oldPassword: z.string(),
    newPassword: passwordSchema,
    newPasswordConfirm: z.string(),
  })
  .superRefine((data, ctx) => {
    if (data.newPassword !== data.newPasswordConfirm) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["newPasswordConfirm"],
        message: "Passwords do not match.",
      });
    }
  });

type FormData = z.infer<typeof schema>;

function ChangePasswordForm() {
  const { handleSubmit, register, setError, formState } = useForm<FormData>({
    resolver: zodResolver(schema),
  });
  const { errors, isSubmitSuccessful, isSubmitting } = formState;
  const modals = useModals();

  async function onSubmit(data: FormData) {
    try {
      // check if new passwords match
      if (data.newPassword !== data.newPasswordConfirm) {
        throw new Error("Passwords do not match.");
      }

      // get credentials
      const credential = EmailAuthProvider.credential(data.email, data.oldPassword);

      // Now reauthenticate the user
      await reauthenticateWithCredential(auth.currentUser!, credential);

      // user re-authenticated
      // Now change the password
      await updatePassword(auth.currentUser!, data.newPassword);

      // Password updated!
    } catch (error: any) {
      setError("root", { message: parseError(error) });
    }
  }

  if (isSubmitSuccessful) {
    return (
      <div>
        <div className="flex items-center justify-start">
          <Icon name="checkCircle" className="h-6 w-6 text-green-500 mr-2" />
          <p className="text-gray-400">Your password has been changed.</p>
        </div>

        <button type="button" className="btn w-full mt-6 bg-gray-800 hover:bg-gray-700" onClick={() => modals.close()}>
          Close
        </button>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-4">
        <label htmlFor="email" className="label">
          Email
        </label>
        <input
          type="email"
          {...register("email", { required: true })}
          placeholder="Your email"
          className="input w-full"
        />
        {errors.email?.message && <p className="pt-2 text-sm text-red-500">{errors.email.message}</p>}
      </div>

      <div className="mb-4">
        <label htmlFor="oldPassword" className="label">
          Old password
        </label>
        <input
          type="password"
          {...register("oldPassword", { required: true })}
          placeholder="Your old password"
          className="input w-full "
        />
        {errors.oldPassword?.message && <p className="pt-2 text-sm text-red-500">{errors.oldPassword.message}</p>}
      </div>

      <div className="mb-4">
        <label htmlFor="newPassword" className="label">
          New password
        </label>
        <input
          type="password"
          {...register("newPassword", { required: true })}
          placeholder="Your new password"
          className="input w-full "
        />
        {errors.newPassword?.message && <p className="pt-2 text-sm text-red-500">{errors.newPassword.message}</p>}
      </div>

      <div className="mb-6">
        <label htmlFor="newPasswordConfirm" className="label">
          Confirm new password
        </label>
        <input
          type="password"
          {...register("newPasswordConfirm", { required: true })}
          placeholder="Confirm your new password"
          className="input w-full "
        />
        {errors.newPasswordConfirm?.message && (
          <p className="pt-2 text-sm text-red-500">{errors.newPasswordConfirm.message}</p>
        )}
      </div>

      <button type="submit" className="mt-2 btn btn-primary w-full" disabled={isSubmitting}>
        {isSubmitting ? <Icon name="spinner" className="animate-spin" /> : "Change password"}
      </button>

      {errors.root && <p className="text-red-500 mt-4">{errors.root.message}</p>}
    </form>
  );
}
