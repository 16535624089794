import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Routes, routes } from "@generouted/react-router/lazy";
import AuthStore from "@/context/Auth";
import { ToastStore } from "@/context/ToastStore";

import "@/index.css"; // tailwindcss
import "@/locales/i18n"; // i18next
import "@fontsource-variable/inter"; // fontsource
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import * as Sentry from "@sentry/react";

if (import.meta.env.VITE_FB_ENV === "prod") {
  Sentry.init({
    dsn: "https://25355ba6a3925f124269f4b3ddff8018@o4505720600002560.ingest.us.sentry.io/4506911210078208",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 10% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
} else if (import.meta.env.VITE_FB_ENV === "staging") {
  Sentry.init({
    dsn: "https://3c832a20cf2fccf00bd5fc2b41c3fbee@o4505720600002560.ingest.us.sentry.io/4506911220498432",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 10% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AuthStore>
      <ToastStore>
        <MyRoutes />
      </ToastStore>
    </AuthStore>
  </React.StrictMode>
);

function MyRoutes() {
  const router = createBrowserRouter(routes);

  const _navigate = router.navigate.bind(router);

  type Listener = () => boolean | Promise<boolean>;
  const listeners: Listener[] = [];

  router.navigate = async (...args) => {
    const params = args as [number];

    if (listeners.length > 0) {
      const promises = listeners.map((fn) => fn());
      const values = await Promise.all(promises);
      const allowed = values.every(Boolean);

      if (!allowed) return;
    }

    return _navigate(...params);
  };

  // const beforeUnload = (e: BeforeUnloadEvent) => {
  //   // Cancel the event.
  //   e.preventDefault();
  //   // Chrome (and legacy IE) requires returnValue to be set.
  //   e.returnValue = "";
  // };

  // function blockNavigation(fn: Listener) {
  //   if (listeners.length === 0) {
  //     addEventListener("beforeunload", beforeUnload, { capture: true });
  //   }

  //   listeners.push(fn);

  //   return () => {
  //     const index = listeners.indexOf(fn);
  //     listeners.splice(index, 1);
  //     if (listeners.length === 0) {
  //       removeEventListener("beforeunload", beforeUnload, { capture: true });
  //     }
  //   };
  // }

  // function useBlocker(dirty: boolean, blocker: Listener) {
  //   useEffect(() => {
  //     if (!dirty) return;
  //     return blockNavigation(blocker);
  //   }, [blocker, dirty]);
  // }

  return <RouterProvider router={router} />;
}
