import { Dialog, Transition } from "@headlessui/react";
import { Fragment, ReactNode } from "react";
import Icon from "./Icon";
import { useModals } from "@/context/ModalStore";
import { twMerge } from "tailwind-merge";

export default function Modal({
  onClose = undefined,
  noClose,
  maxW = "max-w-md",
  children,
}: {
  onClose?: () => void;
  noClose?: boolean;
  maxW?: string;
  children: ReactNode;
}) {
  const modals = useModals();

  function handleClose() {
    if (onClose) onClose();
    modals.close();
  }

  return (
    <Transition appear show>
      {/* todo: unset initial focus? */}
      <Dialog as="div" className="relative z-40" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-600/90" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          {!noClose && (
            <button type="button" className="absolute mt-4 mr-4 top-0 right-0 m-3 p-1 z-50 " onClick={handleClose}>
              <span className="sr-only">Close</span>
              <Icon name="close" className="size-8 sm:size-10 text-gray-200" />
            </button>
          )}

          <div className="flex min-h-full items-center justify-center p-4 pb-32 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={twMerge(
                  "w-full transform overflow-hidden rounded-2xl bg-gray-900 p-6 text-left align-middle shadow-xl transition-all",
                  maxW
                )}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
