import { z } from "zod";

export const passwordSchema = z
  .string()
  .min(8, { message: "Password must be at least 8 characters" })
  // password must contain at least one uppercase letter
  .refine((pass) => /[A-Z]/.test(pass), { message: "Password must contain at least one uppercase letter" })
  // password must contain at least one lowercase letter
  .refine((pass) => /[a-z]/.test(pass), { message: "Password must contain at least one lowercase letter" })
  // password must contain at least one number
  .refine((pass) => /[0-9]/.test(pass), { message: "Password must contain at least one number" });
