import { ReactNode, createContext, useContext, useState } from "react";

const ModalContext = createContext<{
  open: (modalComponent: ReactNode) => void;
  close: () => void;
}>({
  open: () => {},
  close: () => {},
});

export default function ModalStore({ children }: { children: ReactNode }) {
  const [currentModal, setCurrentModal] = useState<ReactNode>(null);

  return (
    <ModalContext.Provider
      value={{
        open: (modalComponent: ReactNode) => {
          setCurrentModal(modalComponent);
        },
        close: () => {
          setCurrentModal(null);
        },
      }}
    >
      {children}

      {currentModal}
    </ModalContext.Provider>
  );
}

export function useModals() {
  const context = useContext(ModalContext);
  if (!context) throw new Error("useModals must be used within a ModalStore");
  return context;
}
