// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { Analytics, getAnalytics } from "firebase/analytics";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Note: these are public keys, so don't worry about them being here

// THIS WILL BE PROD!
const firebaseConfig =
  import.meta.env?.VITE_FB_ENV === "prod"
    ? {
        apiKey: "AIzaSyC94ZWW9h3kQnNGUJ3s4FspvjB0WyKPsaA",
        authDomain: "seagen-chart-audit.firebaseapp.com",
        projectId: "seagen-chart-audit",
        storageBucket: "seagen-chart-audit.appspot.com",
        messagingSenderId: "670805712074",
        appId: "1:670805712074:web:b332e4b9fbd9cf12f06833",
        measurementId: "G-G3SVFY7LLC",
      }
    : {
        apiKey: "AIzaSyBTsYyRP4gZkznAom5-lq54FvXrltbdT5U",
        authDomain: "seagen-chart-audit-staging.firebaseapp.com",
        projectId: "seagen-chart-audit-staging",
        storageBucket: "seagen-chart-audit-staging.appspot.com",
        messagingSenderId: "324653138312",
        appId: "1:324653138312:web:d50c689078145dc6ce7a3d",
        measurementId: "G-RG5FZTSLNZ",
      };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// app check
if (import.meta.env?.PROD) {
  initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(
      import.meta.env?.VITE_FB_ENV === "prod"
        ? "6LfZBeQpAAAAAHYU0mX8HDzXenk7Si2fQGTw_UEH"
        : "6LdmBeQpAAAAAE0kAaqb4kUyiB4OtZ7lb5uSaK5Y"
    ),
    isTokenAutoRefreshEnabled: true,
  });
}

// analytics
export let analytics: Analytics | null = null;
if (import.meta.env?.PROD) {
  analytics = getAnalytics(app);
}

// auth
export const auth = getAuth(app);
if (!import.meta.env?.PROD) {
  connectAuthEmulator(auth, "http://localhost:9099");
}

// db
export const db = getFirestore(app);
if (!import.meta.env?.PROD) {
  connectFirestoreEmulator(db, "localhost", 8080);
}

// functions
const functions = getFunctions(app);
if (!import.meta.env?.PROD) {
  connectFunctionsEmulator(functions, "localhost", 5001);
}

// storage
export const storage = getStorage(app);
if (!import.meta.env?.PROD) {
  connectStorageEmulator(storage, "localhost", 9199);
}
