import { analytics, auth } from "@/firebase";
import { setUserId } from "firebase/analytics";
import { User, onAuthStateChanged } from "firebase/auth";
import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";

interface AuthContextProps {
  user: User | null;
  authLoaded: boolean; // false if we have not yet checked if user is logged in
}

// Create auth context
const AuthContext = createContext<AuthContextProps>({ user: null, authLoaded: false });

// Export auth context
export default function AuthStore({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<AuthContextProps["user"]>(null);
  const [authLoaded, setAuthLoaded] = useState<AuthContextProps["authLoaded"]>(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (import.meta.env.DEV) console.log("DEV: User is logged in!", user);
        setUser(user);
        if (analytics) setUserId(analytics, user.uid, { global: true });
        if (import.meta.env.PROD) Sentry.getCurrentScope().setUser({ id: user.uid });
      } else {
        if (import.meta.env.DEV) console.log("DEV: User is not logged in");
        setUser(null);
        if (analytics) setUserId(analytics, null, { global: true });
        if (import.meta.env.PROD) Sentry.getCurrentScope().setUser(null);
      }

      setAuthLoaded(true);
    });

    return () => unsubscribe();
  });

  return <AuthContext.Provider value={{ user, authLoaded }}>{children}</AuthContext.Provider>;
}

/**
 * Custom hook to use the auth context. YOU MUST CHECK IF USER IS LOGGED IN BEFORE USING THIS HOOK
 *
 * @returns User
 */
export function useUser_strict() {
  const authContext = useContext(AuthContext);

  if (authContext === undefined) {
    throw new Error("useUser must be used within an AuthProvider");
  }

  if (!authContext.user) {
    throw new Error(
      "User is not logged in and you tried to use useUser_strict. Use the useIsLoggedIn hook to check if user is logged in before using useUser_strict"
    );
  }

  return authContext.user;
}

/**
 * @returns true if the auth state has been loaded
 */
export function useAuthLoaded() {
  const authContext = useContext(AuthContext);

  if (authContext === undefined) {
    throw new Error("useAuthLoaded must be used within an AuthProvider");
  }

  return authContext.authLoaded;
}

/**
 * @returns true if the user is logged in, false if not
 */
export function useIsLoggedIn() {
  const authContext = useContext(AuthContext);

  if (authContext === undefined) {
    throw new Error("useIsLoggedIn must be used within an AuthProvider");
  }

  return !!authContext.user;
}
