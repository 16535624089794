// Generouted, changes to this file will be overriden
/* eslint-disable */

import { components, hooks, utils } from '@generouted/react-router/client'

export type Path =
  | `/:lang?`
  | `/:lang?/:formId/disease-characteristics`
  | `/:lang?/:formId/fertility-counselling`
  | `/:lang?/:formId/g-csf-supportive-care`
  | `/:lang?/:formId/initial-treatment-regimen`
  | `/:lang?/:formId/interim-pet-scan/abvd-beacopp/:regimenId/:interimId`
  | `/:lang?/:formId/interim-pet-scan/new/:regimenId`
  | `/:lang?/:formId/interim-pet-scan/o/:regimenId/:interimId`
  | `/:lang?/:formId/patient-details`
  | `/:lang?/:formId/pet-result-after-treatment/:regimenId`
  | `/:lang?/:formId/pet-result-before-treatment/:regimenId`
  | `/:lang?/:formId/start`
  | `/:lang?/:formId/thanks`
  | `/:lang?/:formId/treatment-side-effects`
  | `/:lang?/:formId/unanticipated-events`
  | `/:lang?/login`
  | `/:lang?/register`
  | `/:lang?/reset-password`
  | `/:lang?/reset-password-verify`

export type Params = {
  '/:lang?': { lang?: string }
  '/:lang?/:formId/disease-characteristics': { lang?: string; formId: string }
  '/:lang?/:formId/fertility-counselling': { lang?: string; formId: string }
  '/:lang?/:formId/g-csf-supportive-care': { lang?: string; formId: string }
  '/:lang?/:formId/initial-treatment-regimen': { lang?: string; formId: string }
  '/:lang?/:formId/interim-pet-scan/abvd-beacopp/:regimenId/:interimId': { lang?: string; formId: string; regimenId: string; interimId: string }
  '/:lang?/:formId/interim-pet-scan/new/:regimenId': { lang?: string; formId: string; regimenId: string }
  '/:lang?/:formId/interim-pet-scan/o/:regimenId/:interimId': { lang?: string; formId: string; regimenId: string; interimId: string }
  '/:lang?/:formId/patient-details': { lang?: string; formId: string }
  '/:lang?/:formId/pet-result-after-treatment/:regimenId': { lang?: string; formId: string; regimenId: string }
  '/:lang?/:formId/pet-result-before-treatment/:regimenId': { lang?: string; formId: string; regimenId: string }
  '/:lang?/:formId/start': { lang?: string; formId: string }
  '/:lang?/:formId/thanks': { lang?: string; formId: string }
  '/:lang?/:formId/treatment-side-effects': { lang?: string; formId: string }
  '/:lang?/:formId/unanticipated-events': { lang?: string; formId: string }
  '/:lang?/login': { lang?: string }
  '/:lang?/register': { lang?: string }
  '/:lang?/reset-password': { lang?: string }
  '/:lang?/reset-password-verify': { lang?: string }
}

export type ModalPath = `/-[lang]/change-password`

export const { Link, Navigate } = components<Path, Params>()
export const { useModals, useNavigate, useParams } = hooks<Path, Params, ModalPath>()
export const { redirect } = utils<Path, Params>()
