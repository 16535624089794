import { Toasts } from "@/components/Toast";
import { FC, ReactNode, createContext, useCallback, useContext, useState } from "react";

export type ToastType = {
  id?: string; // a timestamp in ms
  content: ReactNode;
  title?: string;
  type?: "success" | "error" | "warning" | "info";
  lifespanMs?: number; // in ms
};

/**
 * *********************************************************************************
 * ToastContext
 * *********************************************************************************
 */
const ToastContext = createContext<{
  closeToast: (id: string) => void;
  showingToasts: ToastType[];

  // TOASTS
  spawnToast: (toast: ToastType) => void;
}>({
  closeToast: () => console.log("closeToast not implemented"),
  // closeAllToasts: () => console.log("closeAllToasts not implemented"),
  showingToasts: [],
  spawnToast: () => console.log("spawnToast not implemented"),
});

/**
 * *********************************************************************************
 * ToastStore
 * *********************************************************************************
 */
export const ToastStore: FC<{ children: ReactNode }> = ({ children }) => {
  const [showingToasts, setShowingToasts] = useState<ToastType[]>([]);

  const closeToast = useCallback((id: string) => {
    setShowingToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  }, []);

  const spawnToast = (toast: ToastType) => {
    if (showingToasts.length >= 3) {
      // If there are already 3 toasts showing, remove the oldest one
      closeToast(showingToasts[0].id!);
    }

    const newToast: ToastType = {
      ...toast,
      id: toast.id || Date.now().toString(),
      type: toast.type || "info",
    };

    // if the toast's id already exists, replace it
    const toastIndex = showingToasts.findIndex((t) => t.id === newToast.id);
    if (toastIndex !== -1) {
      setShowingToasts((prevToasts) => {
        const newToasts = [...prevToasts];
        newToasts[toastIndex] = newToast;
        return newToasts;
      });
      return;
    }

    // otherwise, add it to the end of the array
    setShowingToasts((prevToasts) => [...prevToasts, newToast]);
  };

  return (
    <ToastContext.Provider
      value={{
        closeToast,
        showingToasts,
        spawnToast,
      }}
    >
      {children}

      {/* Mount the Toasts */}
      <Toasts />
    </ToastContext.Provider>
  );
};

export function useToast() {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
}
