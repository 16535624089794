import { AuthErrorCodes } from "firebase/auth";
import * as Sentry from "@sentry/react";

export function parseErrorMessage(error: any) {
  // error could be anything
  // let's convert it to an object
  const errorObj = anyToErrorObj(error);

  // check if there's a code we can match
  const matcher = somethingToMatchAgainst(errorObj);

  // check if we have a match
  const match = validErrorCodes[matcher];

  // return the match if we have one
  if (match) {
    return match;
  }

  Sentry.captureException(error);

  // otherwise, return generic error message
  return "An error occurred.";
}

function somethingToMatchAgainst(errorObj: Record<string, any>) {
  // check if there's a code we can match
  if (objectHasKey(errorObj, "code")) {
    return `${errorObj.code}`;
  }

  // check if there's a message we can match
  if (objectHasKey(errorObj, "message")) {
    return `${errorObj.message}`;
  }

  return "";
}

function anyToErrorObj(any: any): Record<string, any> {
  if (typeof any === "object") {
    return any;
  }

  try {
    return {
      message: JSON.stringify(any),
    };
  } catch {
    return {
      message: String(any),
    };
  }
}

function objectHasKey(obj: object, key: string) {
  return Object.prototype.hasOwnProperty.call(obj, key);
}

const validErrorCodes: Record<string, string> = {
  [AuthErrorCodes.EMAIL_EXISTS]: "An account with this email already exists.",
  [AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE]: "An account with this email already exists.",
  [AuthErrorCodes.CREDENTIAL_MISMATCH]: "An account with this email already exists.",
  [AuthErrorCodes.INVALID_LOGIN_CREDENTIALS]: "Invalid email or password.",
  [AuthErrorCodes.USER_DELETED]: "Invalid email or password.",
  [AuthErrorCodes.INVALID_PASSWORD]: "Invalid email or password.",
};

type ErrorWithMessage = {
  message: string;
};

type ErrorWithCode = {
  code: string;
};

/**
 *
 * For use within the catch block of a try/catch statement.
 *
 * This function takes any generic error object and returns a string.
 *
 * TODO: handle errors better
 *  Maybe:
 *  - Only show error feedback to users. I.e., invalid password, post must have a title, etc.
 *  - For actual caught errors, log them, report to sentry, and show a generic error message to the user.
 *
 *
 * @param error: any | never
 *
 */
export function parseError(error: any) {
  const errStr = toErrorWithMessage(error).message;

  if (import.meta.env.DEV) {
    console.error(error);
    console.log("message:", error.message);
  }

  return errStr;
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  console.log("maybeError:", maybeError);
  if (isErrorWithCode(maybeError)) {
    console.log("isErrorWithCode");
    // parse firebase errors
    return new Error(parseErrorCode(maybeError));
  }

  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    // create an error object from the maybeError
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as Record<string, unknown>).message === "string"
  );
}

/**
 * For use with firebase errors.
 */
function isErrorWithCode(error: unknown): error is ErrorWithCode {
  return (
    typeof error === "object" &&
    error !== null &&
    "code" in error &&
    typeof (error as Record<string, unknown>).code === "string"
  );
}

function parseErrorCode(error: ErrorWithCode) {
  const errStr = _validErrorCodes[error.code];
  if (errStr) return errStr;

  // if we don't have a match, return the error code
  Sentry.captureException(error);

  return "An error occurred. Please try again.";
}

const _validErrorCodes: Record<string, string> = {
  [AuthErrorCodes.EMAIL_EXISTS]: "An account with this email already exists.",
  [AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE]: "An account with this email already exists.",
  [AuthErrorCodes.CREDENTIAL_MISMATCH]: "An account with this email already exists.",
  [AuthErrorCodes.INVALID_LOGIN_CREDENTIALS]: "Invalid email or password.",
  [AuthErrorCodes.USER_DELETED]: "Invalid email or password.",
  [AuthErrorCodes.INVALID_PASSWORD]: "Invalid email or password.",
};
