import { ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";

export function useLocale() {
  const { i18n } = useTranslation();
  const lang = i18n.language;

  /**
   *
   * @param l [en, fr]
   * @returns
   */
  const l = useCallback(
    (...ls: string[]) => {
      if (lang === "fr" && ls.length > 1) {
        // if in prod && _fr exists in the string, return the first string
        if (import.meta.env.PROD && ls[1].includes("_fr")) {
          return ls[0];
        }

        return ls[1];
      }

      return ls[0];
    },
    [lang]
  );

  /**
   * simple translation component that takes a en and fr prop that will be displayed based on the current locale
   */
  function L({ en, fr, untranslated = true }: { en: ReactNode; fr: ReactNode; untranslated?: boolean }) {
    // untranslated flag is used to debug untranslated components
    // if (untranslated && import.meta.env.DEV)
    //   console.warn("there is an untranslated component in: ", en.valueOf()._source.fileName);

    return lang === "fr" ? fr : en;
  }

  const rootUrlWithLang = `${window.location.origin}${lang === "en" ? "" : `${lang}`}`;

  return { l, L, lang, rootUrlWithLang };
}
