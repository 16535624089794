import { useContext, useEffect } from "react";
import { createPortal } from "react-dom";
import { twMerge } from "tailwind-merge";
import _ from "lodash";
import { ToastType, useToast } from "@/context/ToastStore";
import Icon from "@/components/Icon";

export const Toasts = () => {
  const { showingToasts, closeToast } = useToast();

  return createPortal(
    <>
      {showingToasts.length > 0 ? (
        <div className="fixed bottom-0 right-0 z-50 p-4 space-y-4">
          {showingToasts.map((toast, i) => (
            <Toast key={toast.id} toast={toast} closeToast={closeToast} />
          ))}
        </div>
      ) : null}
    </>,
    document.getElementById("toast-root")!
  );
};

const Toast = ({ toast, closeToast }: { toast: ToastType; closeToast: (id: string) => void }) => {
  useEffect(() => {
    let toastLifespan = toast.lifespanMs;

    if (!toastLifespan && toast.type === "error") {
      // If there is no toastLifespan and the toast is an error, make it a little longer
      toastLifespan = 10000;
    } else {
      // Otherwise, close the toast after 5 seconds by default
      toastLifespan = 5000;
    }

    // If toast.lifespanMs is set, close the toast after that time
    if (toastLifespan && toastLifespan > 0) {
      const toastTimer = setTimeout(() => {
        closeToast(toast.id!);
      }, toastLifespan);

      // Clear the timer when the toast is removed
      return () => {
        clearTimeout(toastTimer);
      };
    }
  }, [toast, closeToast]);

  const toastOuterClasses = twMerge(
    "relative flex flex-col justify-between w-[22rem] max-w-full pt-3.5 pb-4 px-4 rounded-xl drop-shadow-xl",
    toast.type === "success" ? "bg-green-600 text-white" : "",
    toast.type === "error" ? "bg-red-600 text-white" : "",
    toast.type === "warning" ? "bg-yellow-600 text-white" : "",
    toast.type === "info" ? "bg-gray-900 text-white" : ""
  );

  return (
    <div key={toast.id} className={toastOuterClasses}>
      <button
        type="button"
        className="transition-colors rounded-full absolute top-0 right-0 m-1 p-1 bg-transparent hover:bg-white/30"
        onClick={() => closeToast(toast.id!)}
      >
        <Icon name="closeMini" className="h-5 w-5" />
      </button>
      <div>
        {toast.title && <p className="text-base font-bold pr-5 leading-tight">{toast.title}</p>}
        <div className={twMerge("text-base", toast.title ? "" : "pr-5")}>
          {_.isString(toast.content) ? <p>{toast.content}</p> : toast.content}
        </div>
      </div>
    </div>
  );
};
