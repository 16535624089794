import ModalStore from "@/context/ModalStore";
import { useAuthLoaded } from "@/context/Auth";
import { Modals } from "@generouted/react-router/lazy";
import { Outlet, ScrollRestoration, useNavigation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

export default function App() {
  const authLoaded = useAuthLoaded();

  const navigation = useNavigation();
  const active = navigation.state !== "idle";

  const ref = useRef<HTMLDivElement>(null);
  const [animationComplete, setAnimationComplete] = useState(true);

  useEffect(() => {
    if (!ref.current) return;
    if (active) setAnimationComplete(false);

    void Promise.allSettled(ref.current.getAnimations().map(({ finished }) => finished)).then(
      () => !active && setAnimationComplete(true)
    );
  }, [active]);

  // delay render until user auth state is loaded
  // auth state is loaded when user is either User or null, not undefined
  if (!authLoaded) {
    return <>{import.meta.env.DEV && <div>Dev mode: Checking auth...</div>}</>;
  }

  return (
    <>
      <ModalStore>
        {/* navigation progress bar */}
        <div
          role="progressbar"
          aria-hidden={!active}
          aria-valuetext={active ? "Loading" : undefined}
          className="fixed inset-x-0 top-0 left-0 z-50 h-1 animate-pulse"
        >
          <div
            ref={ref}
            className={twMerge(
              "h-full bg-gradient-to-r from-primary-700 to-primary-800 transition-all duration-500 ease-in-out",
              navigation.state === "idle" && animationComplete && "w-0 opacity-0 transition-none",
              navigation.state === "submitting" && "w-4/12",
              navigation.state === "loading" && "w-10/12",
              navigation.state === "idle" && !animationComplete && "w-full"
            )}
          />
        </div>

        <Outlet />
        <Modals />
        <ScrollRestoration />
      </ModalStore>
    </>
  );
}
