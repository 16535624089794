import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

export const defaultNS = "translations";
export const supportedLanguages = ["en", "fr"];

void i18next
  .use(detector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "en",
    defaultNS,
    supportedLngs: supportedLanguages,
    detection: {
      order: ["path", "htmlTag", "cookie", "navigator"],
    },
  });
